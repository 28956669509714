<template>
  <div elevation="0" class="mt-4 mb-8">
    <!-- <div>
      <h3 class="primary--text mb-2">可收貨時段</h3>
    </div> -->

    <!-- <v-divider></v-divider> -->

    <!-- <p>
      請設定所有商店可收貨時段，提供供應商送貨參考，實際到貨時刻仍以供應商出貨時間為準。<br>
      (範例：星期一 10:00-14:00、17:00-21:00，至多設定三個)
    </p> -->
    <!-- <v-row v-for="(dayV, dayI) in list" :key="`day${dayI}`">
      <v-col cols="3">{{ dayV.text }}</v-col>

      <v-col cols="6" >
        <div v-for="(rangeV, rangeI) in dayV.range" :key="`range${rangeI}`">
          <div class="d-flex justify-center align-middle">
            <datetime-picker
                type="time"
                format="HH:mm"
                :range="false"
                :prependIcon="false"
                :timePickerOptions="{start: '00:00', step:'00:30' , end: '24:00', format: 'HH:mm' }"
                v-model="rangeV.start"
                name="range_time_start"
            ></datetime-picker>
            <span class="px-1 py-3">~</span>
            <datetime-picker
                type="time"
                format="HH:mm"
                :range="false"
                :prependIcon="false"
                :timePickerOptions="{start: '00:00', step:'00:30' , end: '24:00', format: 'HH:mm' }"
                v-model="rangeV.end"
                name="range_time_end"
            ></datetime-picker>
            <v-icon @click="editDayRange('delete', dayV, rangeI)">ri-close-line</v-icon>
          </div>
        </div>
      </v-col>
      <v-col cols="3">
        <v-btn
          depressed
          :disabled="dayV.range.length >= 3"
          width="100%"
          color="primary"
          @click="editDayRange('add', dayV)"
          >
          新增時段
        </v-btn>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
export default {
  components: {},
  props: {},
  data: () => ({
    list: [],
    dayEnum: [
      { value: 1, text: "星期一", range: [] },
      { value: 2, text: "星期二", range: [] },
      { value: 3, text: "星期三", range: [] },
      { value: 4, text: "星期四", range: [] },
      { value: 5, text: "星期五", range: [] },
      { value: 6, text: "星期六", range: [] },
      { value: 7, text: "星期日", range: [] },
    ],
    rangeSample: {
      start: "",
      end: "",
    },
  }),
  computed: {
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
  },
  mounted() {
    this.list = _cloneDeep(this.dayEnum);
  },
  watch: {},
  methods: {
    async getReceivingDate() {
      this.$store.dispatch("loading/active");
      try {
        let receivingDate = await this.$api.collection.receivingDateApi.index(
          this.storeId
        );
        this.list = this.dayEnum.map((day) => {
          for (const nowDay of receivingDate) {
            if (day.value === nowDay.week) {
              day.range = nowDay.times;
              day.id = nowDay.id;
              day.originRange = _cloneDeep(nowDay.times);
            }
          }
          if (day.range.length === 0)
            day.range = [_cloneDeep(this.rangeSample)];
          return day;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async addReceivingDate() {
      this.$store.dispatch("loading/active");
      try {
        for (const day of this.list) {
          console.log(
            "_isEqual:",
            day.range,
            day.originRange,
            _isEqual(day.range, day.originRange)
          );
          if (_isEqual(day.range, day.originRange)) continue;
          const postData = {
            week: day.value,
            times: day.range,
          };
          if (day.id) {
            await this.$api.collection.receivingDateApi.update(
              this.storeId,
              day.id,
              postData
            );
          } else {
            await this.$api.collection.receivingDateApi.add(
              this.storeId,
              postData
            );
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    editDayRange(type, day, index) {
      if (type === "add") {
        this.$set(day, "range", [...day.range, _cloneDeep(this.rangeSample)]);
      }
      if (type === "delete") {
        day.range.splice(index, 1);
        this.$set(day, "range", day.range);
      }
    },
  },
};
</script>
